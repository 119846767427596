.outer-cardright {
  padding: 15px 5px;
  width: 150px;
  margin-left: auto;
  margin-right: 0;
  background-color: #fbfbfb;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-top: 25px;
}

.outer-cardleft {
  padding: 15px 5px;
  width: 150px;
  margin-left: 0;
  margin-right: auto;
  background-color: #fbfbfb;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-top: 25px;
}
.services-section h5 {
  font-family: "Inter";
  font-weight: 700;
}

.small-screen-services .col-3 {
  padding-left: 0px;
  padding-right: 0px;
}

.services-section {
  padding: 5% 7%;
}

.small-screen-services {
  display: none;
}
.content-services-text h1 {
  font-size: 4vw;
  color: #003669;
  margin: auto;
  font-family: "Syne";
  font-weight: 700;
}

.content-services-text p {
  text-wrap: balance;
  font-weight: 300;
  font-family: "Inter";
  color: #003669;
  font-size: max(1.4vw, 12px);

  margin-top: 1.2vw;
}

.services-images {
  width: 60px;
  margin-bottom: 10px;
}
.button2 {
  display: none;
}

@media (max-width: 991px) and (min-width: 320px) {
  .big-screen-services {
    display: none;
  }
  .small-screen-services {
    display: flex;
    flex-direction: row;
  }
  .content-services-text {
    text-align: center;
  }
  .servicesButton {
    margin: auto;

    display: none;
  }

  .button2 {
    display: block;
    margin: 25px auto;
  }
}

@media (max-width: 991px) and (min-width: 320px) {
  .outer-cardright {
    margin-left: 0;
    margin-right: 0;
    display: block;
    margin: auto;
    margin-top: 20px;
  }

  .outer-cardleft {
    margin-left: 0;
    margin-right: 0;
    display: block;
    margin: auto;
    margin-top: 20px;
  }
}

@media (max-width: 775px) and (min-width: 600px) {
  .outer-cardright {
    padding: 15px 5px;
    width: 120px;
  }

  .outer-cardleft {
    padding: 15px 5px;
    width: 120px;
  }
  .services-images {
    width: 40px;
    margin-bottom: 10px;
  }
  .small-screen-services h5 {
    font-size: 15px;
  }
}

@media (max-width: 599px) and (min-width: 535px) {
  .outer-cardright {
    padding: 15px 5px;
    width: 105px;
  }

  .outer-cardleft {
    padding: 15px 5px;
    width: 105px;
  }
  .services-images {
    width: 35px;
    margin-bottom: 10px;
  }
  .small-screen-services h5 {
    font-size: 13px;
  }
}

@media (max-width: 534px) and (min-width: 425px) {
  .outer-cardright {
    padding: 15px 5px;
    width: 90px;
  }

  .outer-cardleft {
    padding: 15px 5px;
    width: 90px;
  }
  .services-images {
    width: 30px;
    margin-bottom: 10px;
  }
  .small-screen-services h5 {
    font-size: 11px;
  }
  .small-screen-services .col-3 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 424px) and (min-width: 415px) {
  .outer-cardright {
    padding: 15px 5px;
    width: 80px;
  }

  .outer-cardleft {
    padding: 15px 5px;
    width: 80px;
  }
  .services-images {
    width: 25px;
    margin-bottom: 10px;
  }
  .small-screen-services h5 {
    font-size: 10px;
  }
  .small-screen-services .col-3 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 414px) and (min-width: 320px) {
  .outer-cardright {
    padding: 15px 5px;
    width: 73px;
  }

  .outer-cardleft {
    padding: 15px 5px;
    width: 73px;
  }
  .services-images {
    width: 20px;
    margin-bottom: 10px;
  }
  .small-screen-services h5 {
    font-size: 9px;
  }
  .small-screen-services .col-3 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
