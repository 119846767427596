.whyUs-section {
  padding: 2% 7%;
}

.content1-whyus img {
  margin: auto;
  width: 100%;
  display: block;
}
.small-screen-title {
  display: none;
  font-size: 4vw;
  color: #003669;
  font-family: "Syne";
  font-weight: 700;
}

.content2-whyus {
  margin: auto auto 0px auto;
  display: block;
}
.content2-whyus .content-whyus-text {
  text-align: right;
}

.content-whyus-text h1 {
  font-size: 4vw;
  color: #003669;
  font-family: "Syne";
  font-weight: 700;
}

.content-whyus-text p {
  text-wrap: balance;
  font-weight: 400;
  font-family: "Inter";
  color: #636363;
  font-size: max(1.2vw, 10px);
  margin-top: 2.1vw;
  margin-bottom: 0px;
}

@media (max-width: 768px) and (min-width: 320px) {
  .small-screen-title {
    display: block;
    margin: auto;
    text-align: center;
  }
  .content-whyus-text h1 {
    font-size: 4vw;
    color: #003669;
    display: none;
  }
  .content-whyus-text p {
    font-family: "Inter";
    color: #636363;
    font-size: max(1.2vw, 10px);

    text-align: center;
  }
}
