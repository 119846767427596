.contactus-section {
  padding: 3% 7%;
  margin: auto;
}
.outercontainer-conact {
  padding: 5% 5% 0px 5%;
}
.contacts-card h4 {
  font-family: "Syne";
  font-weight: 700;
}
.contacts-card h5 {
  font-family: "Inter";
  font-weight: 700;
}
.contacts-card {
  display: block;
  margin: 20px auto 0px auto;
  padding: 30px 20px;
  width: 290px;
  height: 290px;
  background-color: #fbfbfb;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease;
}
.cardImage {
  width: 80px;
  margin: 25px 0px;
}

.contactus-title {
  text-align: center;
  font-size: 4vw;
  color: #003669;
  margin: auto;
  font-family: "Syne";
  font-weight: 700;
}
.contacts-card h5 {
  cursor: pointer;
}
.copiedtext {
  /* color: #fbfbfb; */
}

@media (max-width: 1225px) and (min-width: 1070px) {
  .contacts-card {
    margin: auto auto 0px auto;
    padding: 30px 20px;
    width: 250px;
    height: 250px;
  }
  .cardImage {
    width: 75px;
    margin: 20px 0px;
  }

  .contacts-card h5 {
    cursor: pointer;
  }
  .contacts-card h4 {
    font-size: 20px;
  }
  .contacts-card h5 {
    font-size: 17px;
    cursor: pointer;
  }
}

@media (max-width: 1069px) and (min-width: 970px) {
  .contacts-card {
    margin: auto auto 0px auto;
    padding: 30px 20px;
    width: 235px;
    height: 235px;
  }
  .cardImage {
    width: 65px;
    margin: 20px 0px;
  }

  .contacts-card h4 {
    font-size: 18px;
  }
  .contacts-card h5 {
    font-size: 15px;
    cursor: pointer;
  }
}

@media (max-width: 969px) and (min-width: 860px) {
  .contacts-card {
    margin: auto auto 0px auto;
    padding: 30px 20px;
    width: 210px;
    height: 210px;
  }
  .cardImage {
    width: 60px;
    margin: 20px 0px;
  }

  .contacts-card h4 {
    font-size: 16px;
  }
  .contacts-card h5 {
    font-size: 14px;
    cursor: pointer;
  }
  .outercontainer-conact {
    padding: 5% 3% 0px 3%;
  }
}

@media (max-width: 859px) and (min-width: 760px) {
  .contacts-card {
    margin: auto auto 0px auto;
    padding: 20px 20px;
    width: 190px;
    height: 190px;
  }
  .cardImage {
    width: 55px;
    margin: 20px 0px;
  }

  .contacts-card h4 {
    font-size: 15px;

    font-weight: bold;
  }
  .contacts-card h5 {
    font-size: 13px;
    cursor: pointer;
  }
  .outercontainer-conact {
    padding: 5% 3% 0px 3%;
  }
}

@media (max-width: 759px) and (min-width: 675px) {
  .contacts-card {
    margin: auto auto 0px auto;
    padding: 18px 20px;
    width: 170px;
    height: 170px;
  }
  .cardImage {
    width: 50px;
    margin: 18px 0px;
  }

  .contacts-card h4 {
    font-size: 14px;

    font-weight: bold;
  }
  .contacts-card h5 {
    font-size: 12px;
    cursor: pointer;
  }
  .outercontainer-conact {
    padding: 5% 2% 0px 2%;
  }
}

@media (max-width: 674px) and (min-width: 580px) {
  .contacts-card {
    margin: auto auto 0px auto;
    padding: 18px 20px;
    width: 150px;
    height: 150px;
  }
  .cardImage {
    width: 45px;
    margin: 15px 0px;
  }

  .contacts-card h4 {
    font-size: 13px;
    font-weight: bold;
  }
  .contacts-card h5 {
    font-size: 11px;
    cursor: pointer;
  }
  .outercontainer-conact {
    padding: 5% 0px 0px 0px;
  }
}

@media (max-width: 579px) and (min-width: 530px) {
  .contacts-card {
    margin: auto auto 0px auto;
    padding: 18px 20px;
    width: 140px;
    height: 140px;
  }
  .cardImage {
    width: 40px;
    margin: 13px 0px;
  }

  .contacts-card h4 {
    font-size: 12px;
    font-weight: bold;
  }
  .contacts-card h5 {
    font-size: 10px;
    cursor: pointer;
  }
  .outercontainer-conact {
    padding: 5% 0px 0px 0px;
  }
}

@media (max-width: 529px) and (min-width: 450px) {
  .contacts-card {
    margin: auto auto 0px auto;
    padding: 12px 10px;
    width: 120px;
    height: 120px;
  }
  .cardImage {
    width: 35px;
    margin: 13px 0px;
  }

  .contacts-card h4 {
    font-size: 12px;
    font-weight: bold;
  }
  .contacts-card h5 {
    font-size: 10px;
    cursor: pointer;
    text-wrap: balance;
  }
  .outercontainer-conact {
    padding: 5% 0px 0px 0px;
  }
}

@media (max-width: 449px) and (min-width: 390px) {
  .contacts-card {
    margin: auto auto 0px auto;
    padding: 12px 5px;
    width: 95px;
    height: 130px;
  }
  .cardImage {
    width: 33px;
    margin: 13px 0px;
  }

  .contacts-card h4 {
    font-size: 12px;
    font-weight: bold;
  }
  .contacts-card h5 {
    font-size: 10px;
    cursor: pointer;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }
  .outercontainer-conact {
    padding: 5% 0px 0px 0px;
  }
}

@media (max-width: 389px) and (min-width: 320px) {
  .contacts-card {
    margin: auto auto 0px auto;
    padding: 12px 5px;
    width: 90px;
    height: 120px;
  }
  .cardImage {
    width: 30px;
    margin: 13px 0px;
  }

  .contacts-card h4 {
    font-size: 11px;
    font-weight: bold;
  }
  .contacts-card h5 {
    font-size: 8px;
    cursor: pointer;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }
  .outercontainer-conact {
    padding: 5% 0px 0px 0px;
  }
  .contactus-section .col-4 {
    padding-left: 0px;
  }
}
/* 
@media (max-width: 768px) and (min-width: 425px) {
  .contacts-card {
    margin: auto auto 20px auto;
    padding: 30px 20px;
    width: 230px;
  }
  .cardImage {
    width: 65px;
    margin: 30px 0px;
  }

  .contacts-card h5 {
    cursor: pointer;
  }
  .contacts-card h4 {
    cursor: pointer;
  }
}

@media (max-width: 424px) and (min-width: 320px) {
  .contacts-card {
    margin: auto auto 20px auto;
    padding: 30px 20px;
    width: 200px;
  }
  .cardImage {
    width: 55px;
    margin: 30px 0px;
  }

  .contacts-card h5 {
    font-size: 16px;
    color: red;
    cursor: pointer;
  }
  .contacts-card h4 {
    font-size: 16px;
    color: red;
    cursor: pointer;
  }
} */

.contacts-card:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
