.landing-section {
  padding: 5% 7% 0px 7%;
  background-color: #fbfbfb;
  background-image: url("/public/assets/Images/bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.buttons .orange {
  margin-right: 10px;
}
.content1 {
  margin: 0px auto 35px auto;
  display: block;
}
.content1 p {
  text-wrap: balance;
  font-family: "Inter";
  font-weight: 300px;
  color: #003669;
  font-size: max(1.2vw, 10px);
  margin-top: 1.3vw;
  margin-bottom: 2vw;
}
.content1 h1 {
  font-family: "Syne";
  font-weight: 700;
  color: #003669;
  font-size: 4vw;
  margin: auto;
}
.content2 {
  margin: auto auto 0px auto;
}
.content2 img {
  width: 100%;
}

@media (max-width: 768px) and (min-width: 320px) {
  .content1 {
    margin: auto;
    display: block;
    text-align: center;
  }
  .content1 h1 {
    margin: 20px auto 0px auto;
  }

  .content1 {
    margin: 0px auto 30px auto;
    display: block;
  }
}
