.nav {
  padding: 2% 7%;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.nav img {
  width: 15vw;
}

.footer {
  padding: 3% 14%;
}

.footer img {
  width: 5vw;
  margin-right: 20px;
}

@font-face {
  font-family: "Inter";
  src: local("Inter-VariableFont_slnt,wght"),
    url("../public/assets/fonts/Inter-VariableFont_slnt\,wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Syne";
  src: local("Syne-VariableFont_wght"),
    url("../public/assets/fonts/Syne-VariableFont_wght.ttf") format("truetype");
}
