.button {
  height: 40px;
  margin: auto 0px;
  padding: 0px 22px;
  transition: all 0.6s ease;
  font-family: "Syne";
  font-weight: 700;
}
.button2 {
  height: 40px;
  margin: auto 0px;
  padding: 0px 22px;
  transition: all 0.6s ease;
  font-family: "Syne";
  font-weight: 700;
}
.dark {
  background-color: #003669;
  color: #ffff;
  border-radius: 5px;
  border: 0px solid transparent;
}
.orange {
  background-color: #ec5333;
  color: #ffff;
  border-radius: 5px;
  border: 0px solid transparent;
}
.outlineOrange {
  background-color: transparent;
  color: #ec5333;
  border-radius: 5px;
  border: 1px solid #ec5333;
}

/* hover */
.dark:hover {
  background-color: #ffff;
  color: #003669;
  border: 1px solid #003669;
}
.orange:hover {
  background-color: #ffff;
  color: #ec5333;
  border: 1px solid #ec5333;
}

.outlineOrange:hover {
  background-color: #ec5333;
  color: #ffff;
  border: 1px solid #ec5333;
}

@media (max-width: 768px) and (min-width: 450px) {
  .button {
    height: 25px;
    font-size: 13px;
    margin: auto 0px;
    padding: 0px 10px;
  }
  .button2 {
    height: 25px;
    font-size: 13px;
    margin: auto 0px;
    padding: 0px 10px;
  }
}

@media (max-width: 449px) and (min-width: 390px) {
  .button {
    height: 22px;
    font-size: 11px;
    padding: 0px 8px;
  }
  .button2 {
    height: 22px;
    font-size: 11px;
    padding: 0px 8px;
  }
}

@media (max-width: 389px) and (min-width: 320px) {
  .button {
    height: 18px;
    font-size: 10px;
    padding: 0px 6px;
  }
  .button2 {
    height: 18px;
    font-size: 10px;
    padding: 0px 6px;
  }
}
